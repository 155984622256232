<!--
 * @Author: shiwei
 * @Date: 2021-07-03 03:55:16
 * @LastEditTime: 2021-07-25 18:12:43
 * @LastEditors: shiwei
 * @Description: 
 * @FilePath: \shiwei\src\views\Home.vue
-->
<template>
  <el-container>
    <el-aside width="30%" style="padding: 30px">
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="6" style="text-align: left">
          <strong>Server:</strong>
        </el-col>
        <el-col :span="18" style="text-align: left">
          <el-select v-model="selectedServer" placeholder="选择server">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option> </el-select
        ></el-col>
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="6" style="text-align: left">
          <strong>ProcessName:</strong>
        </el-col>
        <el-col :span="18" style="text-align: left">
          <el-input v-model="processName" style="width: 75%"></el-input
        ></el-col>
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="6" style="text-align: left">
          <strong>ProcessCmd:</strong>
        </el-col>
        <el-col :span="18" style="text-align: left">
          <el-input v-model="processCmd" style="width: 75%"></el-input
        ></el-col>
      </el-row>
      <el-button style="margin-top: 10px" type="success" @click="run"
        >Run Process</el-button
      >
      <el-button style="margin-top: 10px" type="primary" @click="refresh"
        >刷新</el-button
      >
      <el-tree
        class="filter-tree"
        :data="treeData"
        :props="defaultProps"
        :highlight-current="true"
        @node-click="selectKey"
        ref="tree"
        style="margin-top: 20px"
      />
    </el-aside>
    <el-main style="width: 50%; padding: 30px; box-sizing: content-box">
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="4" style="text-align: left">
          <strong>ProcessName:</strong>
        </el-col>
        <el-col :span="6" style="text-align: left">
          <strong>{{ selectedProcessName }}</strong>
        </el-col>
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="6" style="text-align: left">
          <strong>Status:</strong>
        </el-col>
        <el-col :span="6" style="text-align: left">
          <strong>{{ selectedProcessStatus }}</strong>
        </el-col>
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="6" style="text-align: left">
          <strong>Pid:</strong>
        </el-col>
        <el-col :span="6" style="text-align: left">
          <strong>{{ selectedProcessPid }}</strong>
        </el-col>
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="6" style="text-align: left">
          <strong>Cmd:</strong>
        </el-col>
        <el-col :span="6" style="text-align: left">
          <strong>{{ selectedProcessCmd }}</strong>
        </el-col>
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="3">
          <el-button type="primary" @click="start">Start</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="success" @click="stop">Stop</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="danger" @click="deleteProcessInfo">Delete</el-button>
        </el-col>
        <el-col :span="6">
          <el-switch
            style="display: block"
            v-model="openLog"
            active-color="#13ce66"
            active-text="打开日志"
            inactive-text="关闭日志"
            @change="handleLog"
          >
          </el-switch>
        </el-col>
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-input
          id="textarea"
          type="textarea"
          :rows="30"
          placeholder=""
          v-model="textArea"
          :readonly="true"
          style="color: black"
        >
        </el-input>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import {
  queryServerInfo,
  queryProcessInfo,
  runProcess,
  startProcess,
  stopProcess,
  deleteProcess,
  getLog
} from "@/api/manager";
import { processObj2TreeData } from "../utils";
import { Message, Notification } from "element-ui";

export default {
  name: "Home",
  data() {
    return {
      options: [],
      processName: "", //进程名称
      processCmd: "", //进行cmd指令
      treeData: [], //左侧树状数据
      //树状结构key值
      defaultProps: {
        children: "children",
        label: "label"
      },
      selectedServer: "",
      selectedProcessName: "",
      selectedProcessStatus: "",
      selectedProcessPid: "",
      selectedProcessCmd: "",
      textArea: "",
      openLog: false, //是否打开日志开关,
      timer: null, //设置定时器，开启后刷新日志信息
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1"
                }
              ]
            }
          ]
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1"
                }
              ]
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1"
                }
              ]
            }
          ]
        },
        {
          label: "一级 3",
          children: [
            {
              label: "二级 3-1",
              children: [
                {
                  label: "三级 3-1-1"
                }
              ]
            },
            {
              label: "二级 3-2",
              children: [
                {
                  label: "三级 3-2-1"
                }
              ]
            }
          ]
        }
      ]
    };
  },

  created() {
    //设置定时任务，获取server和process信息
    //立即执行一次
    document.title = "manager";
    this.refresh();
  },

  beforeDestroy() {
    this.clearTimer();
  },

  methods: {
    //清除定时器
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },

    handleLog(val) {
      if (val) {
        //设置定时器，每3s刷新
        this.log();
        this.timer = setInterval(() => {
          this.log();
        }, 3 * 1000);
      } else {
        //关闭定时器
        this.timer = null;
      }
    },

    //刷新所有的server信息
    updateServerInfo() {
      queryServerInfo({ server: "all" }).then(res => {
        this.options = Object.keys(res).map(item => {
          return {
            value: item,
            label: item
          };
        });
      });
    },
    //刷新左侧树状图数据
    updateTreeData() {
      queryProcessInfo({ server: "all" }).then(res => {
        this.treeData = processObj2TreeData(res);
      });
    },

    refresh() {
      this.updateServerInfo();
      this.updateTreeData();
    },
    //选中左侧树状图中的某一节点
    selectKey(current, e) {
      if (current.children) {
        return;
      } else {
        this.selectedProcessName = current.label;
        this.selectedServer = e.parent.data.label;
        this.fetchProcess();
      }
    },

    //获取单一process信息
    fetchProcess() {
      queryProcessInfo({
        server: this.selectedServer,
        name: this.selectedProcessName
      }).then(res => {
        const { pid = "null", status = "", cmd = "" } = res;
        this.selectedProcessPid = pid;
        this.selectedProcessStatus = status;
        this.selectedProcessCmd = cmd;
        this.log();
      });
    },

    //run process
    run() {
      runProcess({
        server: this.selectedServer,
        name: this.processName,
        cmd: this.processCmd
      }).then(res => {
        this.fetchProcess();
        Notification({
          title: "提示",
          message: "run process success",
          type: "success",
          duration: 1 * 1000
        });
      });
    },

    //start process
    start() {
      startProcess({
        server: this.selectedServer,
        name: this.selectedProcessName
      }).then(res => {
        this.fetchProcess();
        Notification({
          title: "提示",
          message: "start process success",
          type: "success",
          duration: 1 * 1000
        });
      });
    },

    //stop process
    stop() {
      stopProcess({
        server: this.selectedServer,
        name: this.selectedProcessName
      }).then(res => {
        this.fetchProcess();
        Notification({
          title: "提示",
          message: "stop process success",
          type: "success",
          duration: 1 * 1000
        });
      });
    },

    //delete process
    deleteProcessInfo() {
      if (this.selectedProcessStatus == "running") {
        Message({
          message: "需要先stop才能delete",
          type: "warning",
          duration: 2000
        });
      } else {
        deleteProcess({
          server: this.selectedServer,
          name: this.selectedProcessName
        }).then(res => {
          this.fetchProcess();
          Notification({
            title: "提示",
            message: "delete process success",
            type: "success",
            duration: 1 * 1000
          });
        });
      }
    },

    log() {
      if (this.selectedServer && this.selectedProcessName) {
        getLog({
          server: this.selectedServer,
          name: this.selectedProcessName
        }).then(res => {
          const { log = "" } = res;
          this.textArea = log;
          this.$nextTick(() => {
            setTimeout(() => {
              const textarea = document.getElementById("textarea");
              textarea.scrollTop = textarea.scrollHeight;
            }, 13);
          });
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-container {
  ::v-deep.el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #409eff;
  }
}
</style>
