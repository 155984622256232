/*
 * @Author: shiwei
 * @Date: 2021-07-13 19:01:14
 * @LastEditTime: 2021-07-25 17:33:57
 * @LastEditors: shiwei
 * @Description:所有接口
 * @FilePath: \shiwei\src\api\index.js
 */
import request from '@/utils/request'
import Qs from "qs"

//登录接口
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

//获取树结构数据
export function scan(data) {
  return request({
    url: '/redis/scan',
    method: 'get',
    params: data
  })
}

//获取指定key的value
export function queryValue(data) {
  return request({
    url: '/redis/get',
    method: 'get',
    params: data
  })
}

//save as new key
export function set(data) {
  return request({
    Headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    url: '/redis/set',
    method: 'post',
    data: Qs.stringify(data)
  })
}

//delete指定key
export function deleteSelectKey(data) {
  return request({
    url: '/redis/delete',
    method: 'delete',
    data
  })
}