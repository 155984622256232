/*
 * @Author: shiwei
 * @Date: 2021-07-07 18:57:52
 * @LastEditTime: 2021-07-13 18:58:29
 * @LastEditors: shiwei
 * @Description:利用cookie存储token作为请求凭据
 * @FilePath: \shiwei\src\utils\auth.js
 */
import Cookies from 'js-cookie'

const TokenKey = 'jwt'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}