/*
 * @Author: shiwei
 * @Date: 2021-07-03 03:55:16
 * @LastEditTime: 2021-07-27 20:34:01
 * @LastEditors: shiwei
 * @Description: 
 * @FilePath: \shiwei\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Manager from "../views/Manager.vue"
import Login from '@/views/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/login",
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/redis',
    name: 'Home',
    component: Home
  },
  {
    path: '/manager',
    name: 'Manager',
    component: Manager
  },
]

const router = new VueRouter({
  routes
})

export default router
