<!--
 * @Author: shiwei
 * @Date: 2021-07-03 03:55:16
 * @LastEditTime: 2021-07-25 18:12:43
 * @LastEditors: shiwei
 * @Description: 
 * @FilePath: \shiwei\src\views\Home.vue
-->
<template>
  <el-container class="contanier">
    <el-aside width="30%" style="padding: 30px">
      <el-row>
        <el-col :span="6">
          <el-button type="warning" @click="deleteKey" style="width: 100px"
            >Delete</el-button
          >
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="save" style="width: 100px"
            >Save</el-button
          >
        </el-col>
        <el-col :span="6">
          <el-button type="success" @click="saveAsNewKey" style="width: 150px"
            >Save As New Key</el-button
          >
        </el-col>
      </el-row>
      <el-row style="line-height: 40px">
        <el-col :span="24" style="text-align: left"
          >当前选中的Key：<strong style="color: #67c23a">{{
            keySelect
          }}</strong></el-col
        >
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="24" style="text-align: left"
          >输入过滤文本：<el-input
            v-model="filterText"
            @change="updateTreeData"
            style="width: 75%"
          ></el-input
        ></el-col>
      </el-row>
      <el-row style="line-height: 40px; margin-top: 10px">
        <el-col :span="24" style="text-align: left"
          >输入new Key：<el-input v-model="newKey" style="width: 75%"></el-input
        ></el-col>
      </el-row>
      <el-tree
        class="filter-tree"
        :data="treeData"
        :props="defaultProps"
        @node-click="selectKey"
        ref="tree"
        style="margin-top: 20px"
      />
    </el-aside>
    <el-main style="width: 50%; padding: 30px; box-sizing: content-box">
      <el-select
        v-model="editorType"
        placeholder="选择编辑器类型"
        @change="changeEditorType"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span style="text-align: left"
        >当前编辑器类型：<strong style="color: #f56c6c">{{
          editorType
        }}</strong></span
      >
      <div
        ref="editor"
        id="editor"
        style="height: 95%; width: 100%; text-align: left; margin-top: 15px"
      />
    </el-main>
  </el-container>
</template>

<script>
import * as monaco from "monaco-editor";
import { scan, queryValue, set, deleteSelectKey } from "@/api";
import { convertListToTree } from "../utils";
import { Message, Notification } from "element-ui";

export default {
  name: "Home",
  data() {
    return {
      newKey: "", //新建key 文本
      filterText: "strategy", //过滤文本
      treeData: [], //左侧树状数据
      keySelect: "null", //选中的key值
      //树状结构key值
      defaultProps: {
        children: "children",
        label: "label"
      },
      monacoEditor: {},
      editorConfig: {
        language: "json",
        theme: "vs-dark",
        readOnly: false
      },
      options: [
        {
          value: "json",
          label: "json"
        },
        {
          value: "yaml",
          label: "yaml"
        },
        {
          value: "string",
          label: "string"
        }
      ],
      editorType: ""
    };
  },

  created() {
    document.title = "redis";
    this.updateTreeData();
  },

  mounted() {
    //初始化编辑器，确保dom已经渲染，dialog中要写在opened中
    let temp = "";
    this.monacoEditor = monaco.editor.create(this.$refs.editor, {
      ...this.editorConfig,
      value: temp ? JSON.stringify(temp, null, 2) : ""
    });
  },

  beforeDestroy() {
    this.destroyEditor();
  },

  methods: {
    updateTreeData() {
      scan({ prefix: this.filterText })
        .then(res => {
          this.treeData = convertListToTree(res);
          Notification({
            title: "提示",
            message: "update Tree success",
            type: "success",
            duration: 1 * 1000
          });
        })
        .catch(err => {
          Message({
            type: "error",
            err,
            duration: 2000
          });
        });
    },

    changeEditorType(val) {
      this.destroyEditor();
      this.editorConfig.language = val;
      this.monacoEditor = monaco.editor.create(this.$refs.editor, {
        ...this.editorConfig
      });
    },

    selectKey(a) {
      if (a.children) {
        return;
      } else {
        this.keySelect = a.id;
        queryValue({ key: a.id })
          .then(res => {
            const { value = "", type = "" } = res;
            this.editorType = type;
            this.setEditor(value);
          })
          .catch(err => {
            Message({
              type: "error",
              err,
              duration: 2000
            });
          });
      }
    },

    setEditor(value) {
      this.destroyEditor();
      this.editorConfig.language = this.editorType;
      this.monacoEditor = monaco.editor.create(this.$refs.editor, {
        ...this.editorConfig,
        value: value
      });
    },

    deleteKey() {
      deleteSelectKey({ key: this.keySelect }).then(res => {
        this.updateTreeData();
        Notification({
          title: "提示",
          message: "delete key success",
          type: "success",
          duration: 1 * 1000
        });
      });
    },

    saveAsNewKey() {
      if (!this.newKey) {
        Message({
          type: "error",
          message: "必须输入new Key 才能执行",
          duration: 2000
        });
      } else {
        let temp = "";
        if (this.editorType == "json") {
          temp = JSON.stringify(this.queryValue())
            .replace(/\ +/g, "")
            .replace(/[\r\n]/g, "");
        }
        if (this.editorType == "yaml") {
          temp = this.queryValue();
        }
        set({ key: this.newKey, value: temp }).then(res => {
          this.updateTreeData();
          Notification({
            title: "提示",
            message: "save as new key success",
            type: "success",
            duration: 1 * 1000
          });
        });
      }
    },

    queryValue() {
      if (this.editorConfig.language === "json") {
        let temp = this.monacoEditor.getValue();
        let temp1 = temp.replace(/\ +/g, "");
        let temp2 = temp1.replace(/[\r\n]/g, "");
        return JSON.parse(temp2);
      } else if (this.editorConfig.language === "yaml") {
        let temp = this.monacoEditor.getValue();
        return temp;
      }
    },

    save() {
      let temp = "";
      if (this.editorType == "json") {
        temp = JSON.stringify(this.queryValue())
          .replace(/\ +/g, "")
          .replace(/[\r\n]/g, "");
      }
      if (this.editorType == "yaml") {
        temp = this.queryValue();
      }
      console.info(temp);
      set({ key: this.keySelect, value: temp }).then(res => {
        this.updateTreeData();
        Notification({
          title: "提示",
          message: "save  success",
          type: "success",
          duration: 1 * 1000
        });
      });
    },

    changeEditor(data) {
      // 更改editor内容
      this.monacoEditor.setValue(data);
      this.monacoEditor.getAction("editor.action.formatDocument")._run();
    },
    destroyEditor() {
      // 销毁编辑器
      this.monacoEditor.dispose();
    }
  }
};
</script>
<style lang="scss" scoped>
.el-container {
  height: 100%;

  ::v-deep.el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #409eff;
  }
  .el-header {
    height: 80px;
  }
  .monaco-editor {
    width: 900px;
    height: 600px;
  }
}
</style>
