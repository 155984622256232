/*
 * @Author: shiwei
 * @Date: 2021-07-07 18:56:18
 * @LastEditTime: 2021-07-25 17:30:19
 * @LastEditors: shiwei
 * @Description:统一封装axios
 * @FilePath: \shiwei\src\utils\request.js
 */
import axios from 'axios'
import { Message, Notification } from 'element-ui'
import { getToken } from '@/utils/auth'
import router from "@/router"

// 创建axios实例
const service = axios.create({
  baseURL: "https://www.xcctrade.top/",
  withCredentials: true, // 携带cookie
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    const { url = "" } = config
    let jwt = getToken()
    //没有jwt且不为登录接口时，跳转到登录页面
    if (url !== "/auth/login" && !jwt) {
      //登录接口无需检查token
      Message({
        message: "登录已过期，请重新登录",
        type: 'error',
        duration: 3 * 1000
      })
      router.push("/login")
    } else {
      config.headers['jwt'] = jwt
      return config
    }
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  res => {
    // 成功响应的拦截    
    return Promise.resolve(res.data)
  }, err => {
    // 失败响应的拦截
    return Promise.reject(err.response)
  }
)

export default service