/*
 * @Author: shiwei
 * @Date: 2021-07-13 19:01:14
 * @LastEditTime: 2021-07-25 17:33:57
 * @LastEditors: shiwei
 * @Description:所有接口
 * @FilePath: \shiwei\src\api\index.js
 */
import request from '@/utils/request'
import Qs from "qs"

//获取所有的服务器server信息
export function queryServerInfo(data) {
  return request({
    url: '/manager/get-server',
    method: 'get',
    params: data
  })
}

//获取process信息
export function queryProcessInfo(data){
  return request({
    url:'/manager/get',
    method:'post',
    data
  })
}

//run新的 process
export function runProcess(data) {
  return request({
    url: '/manager/run',
    method: 'post',
    data
  })
}

//start现有的 process
export function startProcess(data) {
  return request({
    url: '/manager/start',
    method: 'post',
    data
  })
}

//stop process
export function stopProcess(data) {
  return request({
    url: '/manager/stop',
    method: 'post',
    data
  })
}

//delete process
export function deleteProcess(data){
  return request({
    url: '/manager/delete',
    method: 'post',
    data
  })
}

//get log
export function getLog(data) {
  return request({
    url: '/manager/log',
    method: 'post',
    data
  })
}