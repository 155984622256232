<!--
 * @Author: shiwei
 * @Date: 2021-07-07 19:36:37
 * @LastEditTime: 2021-07-13 19:44:45
 * @LastEditors: shiwei
 * @Description: 登录页面
 * @FilePath: \shiwei\src\views\Login.vue
-->
<template>
  <div class="login-container">
    <el-form class="demo-ruleForm login-page">
      <h3 class="title">系统登录</h3>
      <el-form-item prop="username">
        <el-input
          type="text"
          v-model="username"
          auto-complete="off"
          placeholder="请输入用户名"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="password"
          auto-complete="off"
          placeholder="请输入密码"
        />
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          type="primary"
          style="width: 100%"
          @click="handleSubmit"
          :loading="logining"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login } from "@/api";
import router from "@/router";
import { Message } from "element-ui";
export default {
  data() {
    return {
      logining: false,
      username: "",
      password: ""
    };
  },

  created(){
    document.title = "login"
  },
  
  methods: {
    handleSubmit() {
      this.logining = true;
      login({ username: this.username, password: this.password })
        .then(res => {
          //成功登录
          this.logining = false;
          const { message = "" } = res;
          Message({
            type: "success",
            message,
            duration: 1000,
            onClose: function() {
              router.push("/redis");
            }
          });
        })
        .catch(err => {
          this.logining = false;
          const { message = "" } = err.data;
          Message({
            type: "error",
            message,
            duration: 2000
          });
        });
    }
  }
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>