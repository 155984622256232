/*
 * @Author: shiwei
 * @Date: 2021-07-05 18:59:50
 * @LastEditTime: 2021-07-27 20:34:24
 * @LastEditors: shiwei
 * @Description: 
 * @FilePath: \shiwei\src\utils\index.js
 */

function Node(label, id) {
  this.label = label;
  this.id = id;
}

function checkExist(node, child_label) {
  if (node.children === undefined) {
    return false;
  }
  for (let i = 0; i < node.children.length; i++) {
    if (node.children[i].label === child_label) {
      return node.children[i];
    }
  }
  return false;
}


export function convertListToTree(list) {
  let root = {};
  let result = [];
  for (let i = 0; i < list.length; i++) {
    let item = list[i];
    let split = item.split(":");
    let isExist = false;
    let node;
    result.forEach((item) => {
      if (item.label === split[0]) {
        isExist = true;
        node = item;
      }
    })
    if (!isExist) {
      result.push({
        label: split[0],
        id: split[0]
      })
      node = result[result.length - 1];
    }
    for (let j = 1; j < split.length; j++) {
      let exist = checkExist(node, split[j]);
      if (exist === false) {
        let tempNode = new Node(split[j], "");
        if (j == split.length - 1) {
          // 只在最后的子节点设置id的属性
          tempNode.id = item;
        }
        if (node.children === undefined) {
          node.children = [];
        }
        node.children.push(tempNode);
        node = tempNode;
      } else {
        node = exist;
      }
    }
  }
  return result;

}

export function processObj2TreeData(data) {
  let res = []
  let temp = Object.keys(data);
  temp.forEach((item) => {
    let childTemp = Object.keys(data[item]);
    let children = childTemp.map((item) => { return { label: item } })
    res.push({
      label: item,
      children
    })
  })
  return res
}

