/*
 * @Author: shiwei
 * @Date: 2021-07-03 03:55:16
 * @LastEditTime: 2021-07-03 04:59:01
 * @LastEditors: shiwei
 * @Description: 
 * 
 * @FilePath: \shiwei\src\main.js
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//import './index.css'
Vue.use(ElementUI);
import VueCodeMirror from 'vue-codemirror';
Vue.use(VueCodeMirror);
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
